.wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  flex-direction: row;
  /* gap: 1rem; */
  column-gap: 4rem;
  row-gap: 2rem;
}

.card {
  width: 310px;
  height: 360px;
  border-radius: 19px;

  padding: 1.5rem;
  background: white;
  position: relative;
  display: flex;
  align-items: flex-start;
  transition: 0.4s ease-out;
  box-shadow: 0px 7px 10px rgba(black, 0.5);
}
.card:hover {
  transform: scale(1.1);
  z-index: 1;
}

.card:hover:before {
  opacity: 1;
}

.card:hover .info {
  opacity: 1;
  transform: translateY(0px);
}

/* .info:before {
  opacity: 1;
} */

/* .card:hover .info {
  margin-top: auto;
  opacity: 1;
  transform: translateY(0px);
} */
.card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  transition: 0.5s;
  opacity: 0;
}

.card .img_card {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 15px;
}

.card .info {
  position: relative;
  margin-top: auto;
  z-index: 3;
  color: white;
  opacity: 0;
  transform: translateY(30px);
  transition: 0.5s;
}

.card .info .h1_card {
  margin: 0px;
}

.card .info .p_card {
  letter-spacing: 1px;
  font-size: 15px;
  margin-top: 8px;
}

.card .info .button_card {
  padding: 0.6rem;
  outline: none;
  border: none;
  border-radius: 3px;
  background: white;
  color: black;
  font-weight: bold;
  cursor: pointer;
  transition: 0.4s ease;
}
.card .info .button_card:hover {
  background: #e31b6e;
  color: white;
}
