.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-inner {
  position: relative;
  border-radius: 30px;
  width: 100%;
  max-width: 70rem;
  max-height: 50rem;
  background-color: #fff;
}

.popup-inner .close-btn {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 1000;
}

.slider-img-lms {
  border: 1px solid rgb(236, 231, 231);
  border-radius: 2rem;
  width: 100%;
  margin: auto;
  padding: 1rem;
}
.button-pop {
  background: transparent;
  border: none !important;
  color: #4b4d52;
  font-size: 1.5em;
  font-weight: bolder;
  text-transform: uppercase;
  transition: 0.1s ease-in-out;
}
.button-pop:hover {
  color: black;
}

.Lms .alice-carousel__prev-btn,
.Lms .alice-carousel__next-btn {
  position: absolute;
  top: 18rem;
  width: auto;
  font-size: 3rem;
  opacity: 0.6;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 600px) {
  .Lms .alice-carousel__prev-btn,
  .Lms .alice-carousel__next-btn {
    top: 6rem;
    opacity: 0.3;
  }
}
